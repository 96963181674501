import Icon from '@/components/utilities/Icon'
import clsx from 'clsx'

interface DaylisButtonProps {
  id: string
  type?: 'primary' | 'secondary' | 'tertiary' | 'dashed'
  text: string
  iconSrc?: string
  iconHeight?: number
  iconPosition?: 'left' | 'right'
  dense?: boolean
  border?: boolean
  disabled?: boolean
  handleClick: () => void
  textTransform?: 'normal-case' | 'uppercase' | 'lowercase' | 'capitalize'
  submitType?: 'submit' | 'button'
  customStyle?: string
}

/**
 * Component that displays a button, configured according to the chosen style.
 *
 * @example
 * <WifButton
 *    type="secondary" // Configures the button styling to be white background, and bordered
 *    text="Add to cart" // The text label of the button
 *    iconSrc="/icons/cart.png" // The name of the file to be used as an icon. Make sure it is present in the static directory
 *    handleClick={() => console.log("Adding to cart!")} // A function to execute when the button is clicked
 * />
 *
 * @component
 */
const WifButton: React.FC<DaylisButtonProps> = ({
  id,
  type = 'primary',
  text,
  iconSrc,
  iconHeight = 30,
  iconPosition = 'left',
  dense = true,
  border = true,
  disabled = false,
  handleClick,
  textTransform = 'normal-case',
  submitType = 'submit',
  customStyle,
}) => {
  const baseButtonClasses = [
    dense ? 'h-[50px]' : 'py-3',
    'px-5',
    'rounded-[10px]',
    'w-full',
    'text-sm',
    'text-center',
    'font-bold',
  ]

  var buttonClasses: string[]
  switch (type) {
    case 'primary':
      buttonClasses = [...baseButtonClasses, 'bg-primary', 'text-white']
      break
    case 'secondary':
      buttonClasses = [
        ...baseButtonClasses,
        border ? 'border-[2px]' : '',
        border ? 'border-primary' : '',
        'text-primary',
      ]
      break
    case 'tertiary':
      buttonClasses = [
        ...baseButtonClasses,
        'bg-wif-orange',
        'text-white',
        'shadow-[0px_4px_14px_0px_rgba(27,71,130,0.19)]',
      ]
      break
    case 'dashed':
      buttonClasses = [
        ...baseButtonClasses,
        'bg-[#FDFAF5]',
        'text-wif-orange',
        'border border-dashed border-[#F3CE93]',
      ]
      break
  }

  return (
    <button
      data-testid={`wif-button-${id}`}
      id={`wif-button-${id}`}
      data-test={`wif-button-${id}`}
      onClick={handleClick}
      className={clsx(
        buttonClasses,
        'disabled:bg-wif-neutral-60/80 disabled:cursor-not-allowed',
        customStyle
      )}
      disabled={disabled}
      type={submitType}
    >
      <div className={clsx(textTransform, 'flex justify-center items-center gap-1')}>
        {iconSrc && iconPosition === 'left' && (
          <Icon iconSrc={iconSrc as string} height={iconHeight} alt={text} />
        )}
        {text}
        {iconSrc && iconPosition === 'right' && (
          <Icon iconSrc={iconSrc as string} height={iconHeight} alt={text} />
        )}
      </div>
    </button>
  )
}

export default WifButton
