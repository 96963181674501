import formatCurrency from '@/lib/formatCurrency'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import Link from 'next/link'
import { CartContext } from 'providers/CartProvider'
import { useContext } from 'react'
import { Product, ProductVariant, useGetProductBySlugLazyQuery } from 'shopify/api'
import { notify } from './WifNotification'
import Icon from './utilities/Icon'

interface ProductCardProps {
  product: Product
  id: number
}

const ProductCard: React.FC<ProductCardProps> = ({ product, id }) => {
  const { t } = useTranslation('common')
  const { addToCart } = useContext(CartContext)
  const variantSize = product.variants.nodes.length

  const [getProduct] = useGetProductBySlugLazyQuery()

  const isMTO = product.tags.some((value) => value.toLowerCase() === 'mto')
  const isMaaS = product.tags.some((value) => value.toLowerCase() === 'maas')

  let sampleQty = 0
  const variantsWithoutSamples = product.variants.nodes.reduce(
    (newVariant: ProductVariant[], variant: ProductVariant) => {
      if (!isMaaS && variant.title.toLowerCase().includes('sample')) {
        sampleQty = sampleQty + 1
        return newVariant
      }

      newVariant.push(variant)
      return newVariant
    },
    []
  )

  if (variantsWithoutSamples.length <= 0) return null

  const variant = variantsWithoutSamples[0]
  let isSale = false
  let discount
  let beforeDiscountPrice
  let highlightPrice
  let realPrice
  let primaryPrice

  let yardPrice
  let normalRollPrice
  let discountPrice
  let beforeDiscountPriceDisplay

  if (isMTO) {
    primaryPrice = variant.priceV2.amount
    if (!isNaN(primaryPrice)) {
      highlightPrice = `${formatCurrency(primaryPrice)}/Kg`
    }
    isSale = variant.priceV2.amount < variant.compareAtPriceV2?.amount
    discount =
      ((variant.compareAtPriceV2?.amount - variant.priceV2.amount) /
        variant.compareAtPriceV2?.amount) *
      100
    beforeDiscountPrice = variant.compareAtPriceV2?.amount
    beforeDiscountPriceDisplay = `${formatCurrency(beforeDiscountPrice)}/Kg`
  } else {
    //@ts-ignore
    const yardQty = product.yards_per_roll?.value
    discountPrice = variant.priceV2?.amount || variant.price
    yardPrice = Math.round(discountPrice / yardQty)
    if (!isNaN(yardPrice)) {
      highlightPrice = `${formatCurrency(yardPrice)}/Yard`
    } else {
      highlightPrice = isMaaS && formatCurrency(variant.priceV2.amount)
    }
    normalRollPrice = variant.compareAtPriceV2?.amount || variant.compareAtPrice
    discount = ((normalRollPrice - discountPrice) / normalRollPrice) * 100
    beforeDiscountPrice = normalRollPrice / yardQty
    if (!isNaN(beforeDiscountPrice)) {
      beforeDiscountPriceDisplay = `${formatCurrency(beforeDiscountPrice)} / Yard`
    }
    isSale = discountPrice < normalRollPrice

    realPrice = `${formatCurrency(discountPrice)} / Roll`
  }

  const onRequestSample = async () => {
    await getProduct({
      variables: {
        slug: product.handle,
        reverseVariant: true,
      },
    }).then((res) => {
      const variants = res.data?.product?.variants.nodes || ([] as ProductVariant[])
      const hasSample = variants?.some((variant) => {
        return variant.title.toLowerCase().includes('sample')
      })
      if (hasSample && variants.length > 0) {
        const sampleVariant = variants[0]
        addToCart(sampleVariant as ProductVariant, 1, product.title)
        notify({
          type: 'info',
          text: t('common:cart.Word_Add_Sample_To_Cart_Success'),
          icon: <Icon iconSrc={'/icons/ic-bag-rich.svg'} width={31} height={36} alt={'cart'} />,
        })
      } else {
        notify({
          type: 'error',
          text: t('common:cart.Word_No_Sample'),
        })
      }
    })
  }

  return (
    <div
      className='bg-white rounded-xl cursor-pointer shadow-[0px_4px_10px_0px_rgba(0,0,0,0.05)] overflow-clip h-full flex flex-col'
      data-test={`product-card-${id}`}
    >
      <Link href={`/${isMTO ? 'made-to-order' : 'product'}/${product.handle}`}>
        {product.featuredImage && (
          <div className='bg-wif-grey relative rounded-xl w-full aspect-square max-h-[200px] overflow-hidden'>
            <Image
              src={product.featuredImage?.url || '/icons/ic-img-placeholder.svg'}
              alt={product.featuredImage?.url || 'Placeholder'}
              width={150}
              height={80}
              className='rounded-lg'
              data-test={`product-card-image-${id}`}
              sizes='100vw'
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
            {isMTO && (
              <div className='bg-primary py-[5px] pl-[10px] pr-[15px] absolute inline-block bottom-0 left-0 text-white rounded-tr-2xl text-xs font-semibold'>
                Made To Order
              </div>
            )}
            {isMaaS && (
              <div className='bg-[#F7941D] py-[5px] pl-[10px] pr-[15px] absolute inline-block bottom-0 left-0 text-white rounded-tr-2xl text-xs font-semibold'>
                WifMaaS
              </div>
            )}
          </div>
        )}
        <div className='px-[5px] py-[5px]'>
          {isMaaS && (
            <div
              className='text-[10px] font-bold text-wif-orange uppercase'
              data-test={`product-card-brand-${id}`}
            >
              {product.vendor}
            </div>
          )}
          <div
            className='text-sm font-bold text-wif-neutral-40'
            data-test={`product-card-title-${id}`}
          >
            {product.title}
          </div>
          {/* Remove PriceDisplay cz different layout with pdp for showing the price */}
          {isSale && !!discount && !!beforeDiscountPriceDisplay && (
            <div data-test='product-card-sale-info' className='flex text-[10px] gap-1'>
              <div
                data-test='product-card-sale-percentage'
                className='bg-wif-orange text-white px-1 pt-0.5 rounded-[4px]'
              >
                {Math.round(discount)}%
              </div>
              <div
                data-test='product-card-compare-at-price'
                className='text-[#575757] line-through text-xs'
              >
                {beforeDiscountPriceDisplay}
              </div>
            </div>
          )}
          <div
            className='text-sm font-extrabold text-wif-orange mt-1'
            data-test={`product-card-unit-price-${id}`}
          >
            {highlightPrice}
          </div>
          {!isMaaS && (
            <div
              className='text-[10px] font-bold text-wif-neutral-100 mt-1'
              data-test={`product-card-roll-price-${id}`}
            >
              {realPrice}
            </div>
          )}
          {!isMaaS && (
            <div className='flex gap-[6px] mt-1'>
              <div
                className='text-[10px] font-medium text-wif-neutral-80'
                data-test={`product-card-total-variant-${id}`}
              >
                {variantSize - sampleQty} {t('Word_Colors')}
              </div>
              {product.totalInventory! > 0 && (
                <>
                  <div className='text-[10px] font-medium text-wif-neutral-80'>
                    <span className='bg-wif-grey rounded-full h-1 w-1 inline-block'></span>
                  </div>
                  <div
                    className='text-[10px] font-medium text-wif-neutral-80'
                    data-test={`product-card-stock-${id}`}
                  >
                    {t('Word_Stock')} {product.totalInventory}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Link>
      <div className={clsx('mx-[5px] mb-[5px] mt-auto')}>
        <button
          onClick={onRequestSample}
          className={clsx(
            'text-center h-8 outline outline-1 w-full rounded-md font-bold',
            isMaaS ? 'bg-primary text-white text-xs' : 'outline-primary text-primary text-sm'
          )}
        >
          {t('common:Word_Request_Sample')}
        </button>
      </div>
    </div>
  )
}

export default ProductCard
