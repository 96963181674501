const locale = 'id-ID'
const currency = 'IDR'

const formatCurrency = (amount: number = 0) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount)
}

export const toCurrency = (amount: number = 0) => {
  return new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency,
  }).format(amount)
}

export default formatCurrency
