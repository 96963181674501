import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface WifNotificationProps {}

const toastContent = (text: string, icon: React.ReactNode) => {
  return (
    <div className='flex gap-2 justify-center items-center'>
      {icon && <div data-test='wif-notification-icon'>{icon}</div>}
      <div data-test='wif-notification-text'>{text}</div>
    </div>
  )
}

const notifyMap = new Map([
  ['success', (text: string, icon: React.ReactNode) => toast.success(toastContent(text, icon))],
  ['error', (text: string, icon: React.ReactNode) => toast.error(toastContent(text, icon))],
  ['info', (text: string, icon: React.ReactNode) => toast.info(toastContent(text, icon))],
])

/**
 * Helper function to trigger a notification.
 * Make sure there is a <WifNotification /> element in the page somewhere
 *
 * @example
 * notify({
 *     text: 'Added to cart',
 *     icon: <Icon iconSrc="/icons/ic-bag.svg" width={20} height ={20}  />
 *     type: 'success' | 'error' | 'info'
 * })
 *
 */
export const notify = ({
  text,
  icon,
  type,
}: {
  text: string
  icon?: React.ReactNode
  type: string
}) => {
  const notify = notifyMap.get(type)
  notify && notify(text, icon)
}

/**
 * Container element for notifications.
 * Trigger it using the exported `notify()` helper function
 * @constructor
 */
export const WifNotification: React.FC<WifNotificationProps> = () => {
  return (
    <ToastContainer
      containerId='wif-toast'
      toastClassName='w-full'
      position='top-center'
      theme={'colored'}
      autoClose={2500}
      hideProgressBar
      draggable
      data-test='wif-notification'
      closeButton={false}
      icon={false}
    />
  )
}
